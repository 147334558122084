var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"project-configuration pt-6"},[(_vm.hideSidebar)?_c('v-col',{staticClass:"project-configuration__sidebar py-0",attrs:{"md":"3"}},[_c('v-list',{staticClass:"pa-0",attrs:{"color":"transparent","dense":"","nav":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('project'))+" ")]),_vm._l((_vm.navItems),function(ref,index){
var title = ref.title;
var icon = ref.icon;
var to = ref.to;
return _c('v-list-item',{key:index,attrs:{"to":to}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)],1)})],2),_c('v-divider',{staticClass:"mt-3 mb-2"}),_c('v-list',{staticClass:"pa-0",attrs:{"color":"transparent","dense":"","nav":""}},[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('Environments'))+" ")]),_c('CyButton',{staticClass:"create-env-btn px-3 mb-1",attrs:{"variant":"tertiary","theme":"primary","height":"40px","icon":"add","lg":"","to":{
          name: 'addServiceEnv',
          params: { mode: 'add-env', backRouteTo: 'projectConfigurationGeneral' },
        }}},[_vm._v(" "+_vm._s(_vm.$t('environment.create'))+"... ")]),_vm._l((_vm._.orderBy(_vm.envs, 'created_at', 'asc')),function(ref){
        var canonical = ref.canonical;
        var id = ref.id;
        var icon = ref.icon;
        var color = ref.color;
return _c('v-list-item',{key:id,attrs:{"to":{ name: 'projectConfigurationEnvironment', params: { envCanonical: canonical } }}},[_c('v-list-item-action',[_c('CyAvatar',{attrs:{"item":{ icon: icon, color: color },"sm":""}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(canonical))])],1)],1)})],2)],1):_vm._e(),_c('v-col',{attrs:{"md":"9"}},[_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }